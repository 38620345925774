import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function Page2() {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div>
        <h1>พุทธประวัติ พระสาวก ศาสนิกชนตัวอย่างและชาดก</h1>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <Viewer
          fileUrl='/pdf/pdf2.pdf'
          plugins={[
              defaultLayoutPluginInstance,
          ]}
          />
        </Worker>
    </div>
  )
}

export default Page2