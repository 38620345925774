import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Page1 from './pages/page1';
import Page2 from './pages/page2';
import Page3 from './pages/page3';
import Page4 from './pages/page4';
import Page5 from './pages/page5';
import Page6 from './pages/page6';
import Page7 from './pages/page7';
import Page8 from './pages/page8';

function App() {
  return (
<div className="App">
      <BrowserRouter>

      <div className="wrapper d-flex align-items-stretch">
			<nav id="sidebar">
				<div className="p-4 pt-5">
		  		<h1><Link to="/" className="logo">พระพุทธศาสนา</Link></h1>
	        <ul className="list-unstyled components mb-5">
            <li>
              <Link to="/">การเผยแผ่พระพุทธศาสนาเข้าสู่ประเทศเพื่อนบ้าน</Link>
	          </li>
	          <li>
	              <Link to="/page2">พุทธประวัติ พระสาวก ศาสนิกชนตัวอย่างและชาดก</Link>
	          </li>
            <li>
              <Link to="/page3">หลักพระธรรม</Link>
            </li>
            <li>
              <Link to="/page4">พระไตรปิฎก</Link>
            </li>
            <li>
              <Link to="/page5">หน้าที่ชาวพุทธ</Link>
            </li>
            <li>
              <Link to="/page6">วันสำคัญทางพระพุทธศาสนา</Link>
            </li>
            <li>
              <Link to="/page7">การบริหารจิตใจและเจริญปัญญา</Link>
            </li>
            <li>
              <Link to="/page8">การปฏิบัติตนตามหลักธรรม</Link>
            </li>
	        </ul>
	      </div>
    	</nav>

      <div id="content" className="p-4 p-md-5 pt-5">

      <Routes>
          <Route path="/" element={<Page1 />} />
          <Route path="/page2" element={<Page2 />} />
          <Route path="/page3" element={<Page3 />} />
          <Route path="/page4" element={<Page4 />} />
          <Route path="/page5" element={<Page5 />} />
          <Route path="/page6" element={<Page6 />} />
          <Route path="/page7" element={<Page7 />} />
          <Route path="/page8" element={<Page8 />} />
        </Routes>
        </div>
		</div>


      </BrowserRouter>

    </div>
  );
}

export default App;
